* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
}

img {
    width: 100%;
    display: block;
    height: auto;
    max-width: 100%;
}

:root {
    --margin-inner: 10mm;
    --primary-color: #432f6d
}

header {
    background: var(--primary-color);
    padding: 10px 0;
}

.logo {
    max-width: 80px;
    margin: auto;
}

.btn {
    display: inline-block;
    padding: 5px 15px;
    background: transparent;
    border-radius: 3px;
    font-weight: 400;
    letter-spacing: 1px;
    cursor: pointer;

}
.btn-primary {
    border: 2px solid var(--primary-color);
    line-height: 1.2;
}
.btn-primary:hover {
    background: var(--primary-color);
    color: white;
}

.container {
    width: 90%;
    margin: 20px auto;
    max-width: 1400px;

}


.form {
    margin-right: auto;
    margin-left: auto;
    padding: 40px;
    border: 1px solid grey;
    border-radius: 20px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.form .btn {
    display: block;
    max-width: 300px;
    margin: 40px auto 0;
    font-size: 20px;
}
.field {
    flex-basis: 200px;
    align-items: center;
    justify-content: center;
    margin: 10px 20px;
}

.field__label {
    flex-basis: 200px;
    margin-bottom: 5px;
    display: block;
    color: var(--primary-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.field input, .field select {
    flex: 1;
    height: 30px;
    padding: 5px;

}
.field input:not([type=radio]):not([type=checkbox]), .field select, .field textarea {
    padding: 5px;
    display: block;
    width: 100%;
    font-family: 'montserrat';
    border: 1px solid lightgrey;
}

.field input[type=radio], .field input[type=checkbox] {
    margin-left: 5px;

}

.field.textarea {
    align-items: flex-start;
}

.field textarea {
    flex: 1;
}

.checkbox-group {
    flex: 1;
    display: flex;
    align-self: flex-start;
    flex-wrap: wrap;
}

.radios-group__item, .checkbox-group__item {
    display: flex;
    align-items: center;
}
.radios-group__item input, .checkbox-group__item input {
    max-width: 15px;
    margin-right: 5px;
}
.checkbox-group__item:not(:last-of-type) {
    margin-right: 10px;
}


/**** */
#outer-preview {
    width: 210mm;
    min-width: 210mm;
    height: 297mm;
    min-height: 297mm;
    margin: auto;
}

.preview {
    width: 210mm;
    min-width: 210mm;
    height: 297mm;
    min-height: 297mm;
    color: #fff;
    display: block;
    text-align: center;
    padding: var(--margin-inner);
    position: relative;
}

.preview > * {
    flex-basis: 100%;;
}



.winecolor {
    position: absolute;
    top: var(--margin-inner);
    left: var(--margin-inner);
    width: 17.68mm;
}

.category {
    font-size: 21pt;
    text-transform: uppercase;
    font-family: 'montserrat', sans-serif;
    font-weight: 600;
}

.category:after {
    content: '';
    margin: 9mm auto 0;
    width: 116.9mm;
    height: 1pt;
    background: #fff;
    display: block;
}

.producer {
    font-size: 42pt;
    line-height: 50pt;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 9.1mm;
}

.cuvee {
    margin-top: 16.8mm;
    font-size: 42pt;
    line-height: 50pt;
    font-weight: 700;
}

.year {
    font-size: 21pt;
    font-weight: 600;
}

.appellation {
    font-weight: 700;
    font-size: 28pt;
    text-transform: capitalize;
}

.center-infos {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: var(--margin-inner);
    right: var(--margin-inner);
    margin: auto;
}

.quote {
    line-height: 29pt;
    font-size: 21pt;
    font-family: 'chau_philomene_oneregular', sans-serif;
    position: absolute;
    bottom: 106mm;
    left: var(--margin-inner);
    right: var(--margin-inner);
    margin: auto;
}

.quote p:first-of-type:before {
    content: '\00AB';
    margin-right: 1.4mm;
}

.quote p:last-of-type:after {
    content: '\00BB';
    margin-left: 1.4mm;
}

.price {
    position: absolute;
    text-align: right;
    bottom: 49mm;
    left: var(--margin-inner);
    right: var(--margin-inner);
    margin: auto;
}

.price .global, .price .detail {
    display: block;
}

.price .global {
    font-size: 70pt;
    font-weight: 700;
}

.price .detail {
    font-weight: 600;
    font-size: 22pt;
    margin-top: 2.8mm;
}

.labels {
    display: flex;
    position: absolute;
    left: var(--margin-inner);
    right: var(--margin-inner);
    bottom: var(--margin-inner);
    justify-content: flex-end;
}

.labels img {
    height: 18.76mm;
    width: auto;
    flex-basis: unset;
}

.labels img:not(:last-of-type) {
    margin-right: 5.6mm;
}

.logo-cash-vin {
    position: absolute;
    right: auto;
    left: var(--margin-inner);
    bottom: var(--margin-inner);
    width: 21.602mm;
}