/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 23, 2021 */


@font-face {
    font-family: 'chau_philomene_oneregular';
    src: url('chauphilomeneone-regular-webfont.woff2') format('woff2'),
    url('chauphilomeneone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-black-webfont.woff2') format('woff2'),
    url('montserrat-black-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;

}


@font-face {
    font-family: 'montserrat';
    src: url('montserrat-extrabold-webfont.woff2') format('woff2'),
    url('montserrat-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-bold-webfont.woff2') format('woff2'),
    url('montserrat-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-semibold-webfont.woff2') format('woff2'),
    url('montserrat-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-medium-webfont.woff2') format('woff2'),
    url('montserrat-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'montserrat';
    src: url('montserrat-regular-webfont.woff2') format('woff2'),
    url('montserrat-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}